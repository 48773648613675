
import "@/assets/sass/template.scss";
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import store from "@/store";
import moment from "moment";
import TableTab from "@/components/kt-datatable/TableTab.vue";
import PaginationUtill from "../util/PaginationUtill";

export default defineComponent({
  name: "DataTable",

  components: {
    TableTab,
  },
  setup() {
    let accountId = store.getters.AccountId;

    const tableValues = ref([]);
    const tableValuesR = ref([]);
    const fromDate = ref(new Date());
    const toDate = ref(new Date());
    const addition = ref(true);
    const removal = ref(false);
    const downloadurl = "api/siteaddittionremoval/downloadElecFile";
    const downloadprops = ref();
    const loading = ref<boolean>(false);
    interface Tableheader {
      name: string;
      key: string;
      sortable: boolean;
    }
    const colnames = ref();
    const TableHeaders = ref<Array<Tableheader>>();
    const OriginalTableHeaders = ref<Array<Tableheader>>();
    TableHeaders.value = PaginationUtill.assetAdditionElectrcity;
    OriginalTableHeaders.value = PaginationUtill.assetAdditionElectrcity;
    colnames.value = PaginationUtill.assetAdditionElectrcity.map((a) => a.key);
    onMounted(() => {
      setCurrentPageBreadcrumbs("Site Additions/Removals Electricity", ["NA"]);
      addition.value = false;
      removal.value = false;
    });

    async function getContent() {
      // addition.value = true;
      loading.value = true;
      const fromdateval: any = fromDate.value;
      const toDateval: any = toDate.value;
      let domainName = store.getters.DomainName;
      const accountInfo: any = {
        accountid: accountId,
        fromdate: moment(fromdateval).format("YYYY-MM-DD 00:00:00"),
        todate: moment(toDateval).format("YYYY-MM-DD 00:00:00"),
        DomainName: domainName,
      };
      downloadprops.value = accountInfo;

      ApiService.post("api/siteaddittionremoval/siteaddrem", accountInfo)
        .then((resp) => {
          tableValues.value = resp.data.elecdetails.map((a) => a);
          tableValuesR.value = resp.data.elecdetailsRemove.map((a) => a);
          addition.value = true;
          loading.value = false;
        })
        .catch((a) => {
          loading.value = false;
          console.log("Api Log Error", a);
        });
    }

    function openAssetAddition() {
      addition.value = true;
      removal.value = false;
    }
    function openAssetRemoval() {
      addition.value = false;
      removal.value = true;
    }
    const pageheaderRemovals = "Site Removals";
    const pageheaderAddition = "Site Additions";
    return {
      OriginalTableHeaders,
      pageheaderAddition,
      pageheaderRemovals,
      getContent,
      TableHeaders,
      colnames,
      fromDate,
      toDate,
      openAssetAddition,
      openAssetRemoval,
      addition,
      removal,
      tableValues,
      tableValuesR,
      loading,
      downloadurl,
      downloadprops,
    };
  },
});
